// import "./src/assets/style.css"
// import "./src/assets/style-rtl.css"
// import "./src/assets/print.css"
// import "./src/assets/style-theme.css"
import "./src/assets/base.scss"
import "./src/assets/header.scss"
import "./src/assets/social-menu.scss"
import "./src/assets/menu.scss"
import "./src/assets/layout.scss"
import "./src/assets/hamburger.scss"
import "./src/assets/footer.scss"
import "./src/assets/portfolio-grid.scss"
import "./src/assets/portfolio-feed.scss"
import "./src/assets/featured-media-background.scss"
import "./src/assets/feature-section.scss"
import "./src/assets/home.scss"
import "./src/assets/forms.scss"
import "./src/assets/grid.scss"

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}
